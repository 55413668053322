import {Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {Vaardigheid, VaardigheidType} from './vaardigheid';
import {map, startWith} from 'rxjs/operators';
import {vaardigheden} from '../vaardigheden';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  selectedVaardigheden: Vaardigheid[] = [];

  myControl = new FormControl();
  options = vaardigheden;
  filteredOptions: Observable<Vaardigheid[]>;

  constructor() {
  }

  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.naam),
        map(naam => naam ? this._filter(naam) : this.options.slice()),
        map(options => options.filter(option => this.selectedVaardigheden.indexOf(option) === -1)),
      );
    this.myControl.setValue('');
  }

  displayFn(user: Vaardigheid): string {
    return user && user.naam ? user.naam : '';
  }

  private _filter(name: string): Vaardigheid[] {
    const filterValue = name.toLowerCase();

    return this.options
      .filter(option => option
        .naam
        .toLowerCase()
        .indexOf(filterValue) === 0);
  }

  voegVaardigheidToe() {
    if (this.myControl.value) {
      this.selectedVaardigheden.push(this.myControl.value);
    }
    this.myControl.setValue('');
  }

}
