import {Pipe, PipeTransform} from '@angular/core';
import {Vaardigheid} from './vaardigheid';

@Pipe({
  name: 'vaardigheidfilter',
  pure: false
})
export class VaardigheidfilterPipe implements PipeTransform {
  transform(vaardigheden: Vaardigheid[], term: string): any {
    if (!term || term.length === 0) {
      return vaardigheden;
    }
    return vaardigheden.filter(vaardigheid => vaardigheid.naam.toLowerCase().indexOf(term.toLowerCase()) !== -1);
  }
}
