export enum VaardigheidType {
  FRAMEWORK,
  PROG_LANGUAGE,
  TOOLS,
  ARCHITECTURE,
  DATABASE,
  FRONTEND,
  TESTING,
  BUILD,
  INFRA,
  VERSION_CONTROL,
  MESSAGING
}

export interface Vaardigheid {
  naam: string;
  level: number;
  type: VaardigheidType[];
}
