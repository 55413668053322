<div fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="center start">
    <div fxFlex.gt-xs="0 1 1024px" fxFlex="0 1 100%">
      <div fxLayout.gt-xs="row" fxLayout.lt-sm="column" fxLayoutAlign.gt-xs="space-evenly start" fxLayoutAlign.lt-sm="space-evenly" class="ptb-48" fxLayoutGap="24px">
        <div fxLayout="column" fxFlex.gt-xs="30%" fxFlex.lt-sm="100%" class="profile-background p-24">
          <div class="p-24">
            <img class="profile-image rounded img-responsive" src="assets/photo.jpg" alt="Xander Arling">
          </div>

          <h1 class="profile-strong">Personalia</h1>

          <div>
            <div class="profile-list-item">
              <div fxLayout="row" fxLayoutAlign="space-between start">
                <span>Naam:</span>
                <span>Xander Arling</span>
              </div>
            </div>
            <div class="profile-list-item">
              <div fxLayout="row" fxLayoutAlign="space-between start">
                <span>Geboortedatum:</span>
                <span>01-04-1980</span>
              </div>
            </div>
            <div class="profile-list-item">
              <div fxLayout="row" fxLayoutAlign="space-between start">
                <span>Nationaliteit:</span>
                <span>Nederlandse</span>
              </div>
            </div>
            <div class="profile-list-item">
              <div fxLayout="row" fxLayoutAlign="space-between start">
                <span>Talen:</span>
                <span>Nederlands, Engels</span>
              </div>
            </div>
          </div>


          <h1 class="profile-strong">Contact</h1>

          <div>
            <div class="profile-list-item">
              <div fxLayout="row" fxLayoutAlign="space-between start">
                <span>Mobiel:</span>
                <span><a href="tel:+31657569986">+31657569986</a></span>
              </div>
            </div>
            <div class="profile-list-item">
              <div fxLayout="row" fxLayoutAlign="space-between start">
                <span>E-mail:</span>
                <span><a target="_blank" rel="noopener" href="mailto:xander.arling@gmail.com">xander.arling@gmail.com</a></span>
              </div>
            </div>

            <h1 class="profile-strong">Socials</h1>
            <div class="icons">
              <div fxLayout="row" fxLayoutAlign="space-evenly start">
                <a target="_blank" rel="noopener" title="LinkedIn"
                   href="https://www.linkedin.com/in/xarling?originalSubdomain=nl"><span class="fab fa-linkedin-in"
                                                                                         aria-hidden="true"></span></a>
                <a target="_blank" rel="noopener" title="Twitter" href="https://twitter.com/xarling"><span class="fab fa-twitter"
                                                                                            aria-hidden="true"></span></a>
                <a target="_blank" rel="noopener" title="Strava" href="http://www.strava.com/athletes/2564676"><span
                  class="fab fa-strava" aria-hidden="true"></span></a>

                <a target="_blank" rel="noopener" title="Facebook" href="https://www.facebook.com/xander.arling.3"><span
                  class="fab fa-facebook" aria-hidden="true"></span></a>

              </div>
            </div>

            <h1 class="profile-strong">Hobbies</h1>
            <div class="icons">
              <div fxLayout="row" fxLayoutAlign="space-evenly start">
                <i class="fas fa-volleyball-ball" aria-hidden="true" title="Volleybal"></i>
                <i class="fas fa-gamepad" aria-hidden="true" title="Gaming"></i>
                <i class="fas fa-running" aria-hidden="true" title="Running"></i>
                <i class="fas fa-biking" aria-hidden="true" title="Cycling"></i>
              </div>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex.gt-xs="70%" fxFlex.lt-sm="100%" fxLayoutGap="24px">
          <div class="p-24 content-background">
            <h1 class="profile-strong">Profiel</h1>

            Hallo, mijn naam is Xander Arling. Ik ben een ervaren software ontwikkelaar met een passie voor elegante
            oplossingen die werken.
            Het liefst werk ik samen in een goed team om oplossingen neer te zetten die gebruikers optimaal ondersteunen
            in hun activiteiten.
            <br/>
            <!--            <br/>-->
            <!--            In grote teams heb ik diverse complexe projecten bij verschillende bedrijven succesvol afgerond.-->
            <!--            Op dit moment werk ik tot juni 2021 bij DUO in Groningen.-->

            <strong>Vanaf juli 2021 ben ik beschikbaar voor een opdracht.</strong>
            <br/>
            <span>Mijn CV is <a href="http://www.xanderarling.nl/cv/CV-Xander-Arling_30-04-2018.pdf" target="_blank" rel="noopener"
                                title="CV downloaden">hier te downloaden</a></span>
          </div>
          <div class="p-24 content-background">
            <h1 class="profile-strong">Werkervaring</h1>

            <h2 class="profile-title">Software ontwikkelaar</h2>
            <span>DUO - Facet (Groningen)</span>
            <br>
            <span>Juli 2019 - heden</span>
            <br>
            <br>
            Werkt binnen het Facet team aan software voor het plannen,
            uitvoeren en analyseren van examens. Xander is met het team
            verantwoordelijk voor de architectuur, bouw en geautomatiseerde
            tests van de systemen.

            <br/><br/>

            <mat-chip-list aria-label="Technieken">
              <mat-chip>Java</mat-chip>
              <mat-chip>Spring (Boot)</mat-chip>
              <mat-chip>Docker</mat-chip>
              <mat-chip>REST</mat-chip>
              <mat-chip>Hibernate</mat-chip>
              <mat-chip>Angular</mat-chip>
              <mat-chip>Vue.js</mat-chip>
              <mat-chip>Cypress</mat-chip>
              <mat-chip>Vuex</mat-chip>
              <mat-chip>Postgres</mat-chip>
              <mat-chip>Cassandra</mat-chip>
              <mat-chip>Kafka</mat-chip>
            </mat-chip-list>
            <br>
            <hr class="dashed-border">
            <h2 class="profile-title">Software ontwikkelaar</h2>
            <span>DICTU - TenderNed (Groningen)</span>
            <br>
            <span>Juni 2018 - Juli 2019</span>
            <br>
            <br>

            Werkzaam als software ontwikkelaar voor TenderNed, het aanbestedingsplatform van de Nederlandse overheid.
            Xander is met
            het team verantwoordelijk voor de architectuur en bouw van de systemen.

            <br><br>
            <mat-chip-list aria-label="Technieken">
              <mat-chip>Java 8</mat-chip>
              <mat-chip>JBoss</mat-chip>
              <mat-chip>JEE</mat-chip>
              <mat-chip>Docker</mat-chip>
              <mat-chip>REST</mat-chip>
              <mat-chip>Hibernate</mat-chip>
              <mat-chip>Angular</mat-chip>
              <mat-chip>JMS</mat-chip>
              <mat-chip>Typescript</mat-chip>
              <mat-chip>Postgres</mat-chip>
            </mat-chip-list>

            <br>
            <hr class="dashed-border">
            <h2 class="profile-title">Software Architect</h2>
            <span>RedVan</span>
            <br>
            <span>Oktober 2017 - December 2020</span>
            <br>
            <br>

            RedVan optimaliseert de productie van varkensvlees. Door gebruik te maken van slimme software die het
            productieproces volgt vanaf de inseminatie tot aan het oogsten (slachten), is de producent in staat beter en
            zorgvuldiger te produceren.
            Binnen het team is Xander verantwoordelijk voor de architectuur van de systemen en het ontwikkelen van
            eenvoudig te begrijpen visualisaties voor de gebruiker.

            <br><br>
            <mat-chip-list aria-label="Technieken">
              <mat-chip>Java</mat-chip>
              <mat-chip>Spring (Boot)</mat-chip>
              <mat-chip>REST</mat-chip>
              <mat-chip>MongoDB</mat-chip>
              <mat-chip>Hibernate</mat-chip>
              <mat-chip>Angular</mat-chip>
              <mat-chip>Typescript</mat-chip>
              <mat-chip>Chart.js</mat-chip>
            </mat-chip-list>

            <br>
            <hr class="dashed-border">
            <h2 class="profile-title">Software Architect</h2>
            <span>DUO - DFKO (Groningen)</span>
            <br>
            <span>Augustus 2015 - Juni 2018</span>
            <br>
            <br>

            Binnen het programma Directe Financiering Kinderopvang is Xander met het
            team verantwoordelijk voor de architectuur en bouw van de applicaties om directe financiering
            kinderopvang mogelijk te maken.

            <br><br>

            <mat-chip-list aria-label="Technieken">
              <mat-chip class="special-chip">Java 8</mat-chip>
              <mat-chip>JBoss</mat-chip>
              <mat-chip>REST</mat-chip>
              <mat-chip>Spring (Boot)</mat-chip>
              <mat-chip>Hibernate</mat-chip>
              <mat-chip>Angular</mat-chip>
              <mat-chip>JMS</mat-chip>
              <mat-chip>Typescript</mat-chip>
              <mat-chip>DB2</mat-chip>
            </mat-chip-list>
            <br>
            <div class="border"></div>
            <i>Voor meer werkervaring zie <a href="http://www.xanderarling.nl/cv/CV-Xander-Arling_30-04-2018.pdf" target="_blank" rel="noopener"
                                             title="CV downloaden">CV</a>.</i>

          </div>

          <div class="p-24 content-background vaardigheden">
            <h1 class="profile-strong">Vaardigheden</h1>

            <mat-form-field>
              <mat-label class="white">Filter vaardigheid</mat-label>
              <input type="text" matInput [formControl]="myControl">
            </mat-form-field>

            <div class="profile-list-item">
              <div fxLayout="row" fxLayoutAlign="space-between start" *ngFor="let vaardigheid of options | vaardigheidfilter : myControl.value">
                <span fxFlex.gt-xs="10%" fxShow.lt-sm="false"></span>
                <span fxFlex.gt-xs="30%" fxFlex.lt-sm="40%">{{vaardigheid.naam}}</span>
                <span fxFlex.gt-xs="50%" fxFlex.lt-sm="60%"><mat-icon class="star"
                                *ngFor="let stars of [].constructor(vaardigheid.level)">star</mat-icon></span>
                <span fxFlex.gt-xs="10%" fxShow.lt-sm="false"></span>
              </div>
            </div>


          </div>

        </div>

      </div>
    </div>
  </div>
</div>
