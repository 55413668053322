import {Vaardigheid, VaardigheidType} from './home/vaardigheid';

export const vaardigheden: Vaardigheid[] =
  [
    /** frameworks */
    {naam: 'Spring', level: 5, type: [VaardigheidType.FRAMEWORK]},
    {naam: 'Spring Boot', level: 5, type: [VaardigheidType.FRAMEWORK]},
    {naam: 'Spring Data', level: 5, type: [VaardigheidType.FRAMEWORK]},
    {naam: 'Spring MVC', level: 3, type: [VaardigheidType.FRAMEWORK]},
    {naam: 'Angular', level: 5, type: [VaardigheidType.FRONTEND]},
    {naam: 'Redux', level: 4, type: [VaardigheidType.FRONTEND, VaardigheidType.FRAMEWORK]},
    {naam: 'Vue', level: 3, type: [VaardigheidType.FRONTEND, VaardigheidType.FRAMEWORK]},
    {naam: 'React', level: 1, type: [VaardigheidType.FRONTEND, VaardigheidType.FRAMEWORK]},
    {naam: 'Vuex', level: 3, type: [VaardigheidType.FRONTEND, VaardigheidType.FRAMEWORK]},
    {naam: 'Material', level: 3, type: [VaardigheidType.FRONTEND, VaardigheidType.FRAMEWORK]},
    {naam: 'Bootstrap', level: 4, type: [VaardigheidType.FRONTEND, VaardigheidType.FRAMEWORK]},
    {naam: 'CSS', level: 4, type: [VaardigheidType.FRONTEND]},
    {naam: 'HTML', level: 5, type: [VaardigheidType.FRONTEND]},
    {naam: 'XML', level: 5, type: [VaardigheidType.FRAMEWORK]},
    {naam: 'Crnk', level: 3, type: [VaardigheidType.FRAMEWORK]},
    {naam: 'Camunda', level: 3, type: [VaardigheidType.FRAMEWORK]},
    {naam: 'JSF', level: 1, type: [VaardigheidType.FRAMEWORK]},

    /** Messaging Events */
    {naam: 'Kafka', level: 4, type: [VaardigheidType.MESSAGING]},
    {naam: 'JMS', level: 4, type: [VaardigheidType.MESSAGING]},

    /** infra */
    {naam: 'Docker', level: 4, type: [VaardigheidType.INFRA]},
    {naam: 'Kubernetes', level: 2, type: [VaardigheidType.INFRA]},
    {naam: 'AWS', level: 2, type: [VaardigheidType.INFRA]},

    /** build tooling */
    {naam: 'Maven', level: 4, type: [VaardigheidType.BUILD]},
    {naam: 'Groovy', level: 2, type: [VaardigheidType.BUILD]},
    {naam: 'Jenkins pipeline', level: 3, type: [VaardigheidType.BUILD]},
    {naam: 'Angular CLI', level: 4, type: [VaardigheidType.BUILD, VaardigheidType.TOOLS]},
    {naam: 'NPM', level: 4, type: [VaardigheidType.BUILD, VaardigheidType.TOOLS]},
    {naam: 'Webpack', level: 3, type: [VaardigheidType.BUILD, VaardigheidType.TOOLS]},
    {naam: 'Jenkins', level: 4, type: [VaardigheidType.BUILD, VaardigheidType.TOOLS]},

    /** testing */
    {naam: 'JUnit', level: 5, type: [VaardigheidType.TESTING]},
    {naam: 'Cucumber', level: 4, type: [VaardigheidType.TESTING]},
    {naam: 'Jasmine', level: 4, type: [VaardigheidType.TESTING, VaardigheidType.FRONTEND]},
    {naam: 'Cypress', level: 4, type: [VaardigheidType.TESTING, VaardigheidType.FRONTEND]},
    {naam: 'Selenium', level: 4, type: [VaardigheidType.TESTING, VaardigheidType.FRONTEND]},
    {naam: 'Serenity', level: 4, type: [VaardigheidType.TESTING, VaardigheidType.FRONTEND]},

    /** programmeer talen */
    {naam: 'Java', level: 5, type: [VaardigheidType.PROG_LANGUAGE]},
    {naam: 'Kafka', level: 3, type: [VaardigheidType.PROG_LANGUAGE]},
    {naam: 'Scala', level: 2, type: [VaardigheidType.PROG_LANGUAGE]},
    {naam: 'Typescript', level: 5, type: [VaardigheidType.PROG_LANGUAGE, VaardigheidType.FRONTEND]},
    {naam: 'Javascript', level: 5, type: [VaardigheidType.PROG_LANGUAGE, VaardigheidType.FRONTEND]},
    {naam: 'C++', level: 2, type: [VaardigheidType.PROG_LANGUAGE]},
    {naam: 'PHP', level: 3, type: [VaardigheidType.PROG_LANGUAGE]},
    {naam: 'Node.js', level: 4, type: [VaardigheidType.PROG_LANGUAGE]},

    /** architectuur */
    {naam: 'Microservices', level: 4, type: [VaardigheidType.ARCHITECTURE]},
    {naam: 'CQRS', level: 3, type: [VaardigheidType.ARCHITECTURE]},
    {naam: 'Event sourcing', level: 3, type: [VaardigheidType.ARCHITECTURE]},
    {naam: 'REST', level: 4, type: [VaardigheidType.ARCHITECTURE]},
    {naam: 'SOAP', level: 4, type: [VaardigheidType.ARCHITECTURE]},
    {naam: 'SOA', level: 3, type: [VaardigheidType.ARCHITECTURE]},
    {naam: 'WSDL', level: 3, type: [VaardigheidType.ARCHITECTURE]},
    {naam: 'Swagger', level: 4, type: [VaardigheidType.ARCHITECTURE, VaardigheidType.TOOLS]},
    {naam: 'JSON:API', level: 4, type: [VaardigheidType.ARCHITECTURE, VaardigheidType.TOOLS]},
    {naam: 'OpenAPI', level: 4, type: [VaardigheidType.ARCHITECTURE, VaardigheidType.TOOLS]},

    /** database */
    {naam: 'Cassandra', level: 4, type: [VaardigheidType.DATABASE]},
    {naam: 'Postgress', level: 4, type: [VaardigheidType.DATABASE]},
    {naam: 'MariaDB', level: 4, type: [VaardigheidType.DATABASE]},
    {naam: 'Mongo', level: 4, type: [VaardigheidType.DATABASE]},
    {naam: 'Mysql', level: 4, type: [VaardigheidType.DATABASE]},

    /** tools */
    {naam: 'Eclipse', level: 4, type: [VaardigheidType.TOOLS]},
    {naam: 'IntelliJ', level: 5, type: [VaardigheidType.TOOLS]},
    {naam: 'Sublime', level: 4, type: [VaardigheidType.TOOLS]},
    {naam: 'Visual Studio Code', level: 4, type: [VaardigheidType.TOOLS]},
    {naam: 'Lightroom', level: 3, type: [VaardigheidType.TOOLS]},
    {naam: 'Photoshop', level: 3, type: [VaardigheidType.TOOLS]},
    {naam: 'Kibana', level: 3, type: [VaardigheidType.TOOLS]},
    {naam: 'Discord', level: 4, type: [VaardigheidType.TOOLS]},
    {naam: 'Postman', level: 4, type: [VaardigheidType.TOOLS]},


    /** version control */
    {naam: 'Git', level: 4, type: [VaardigheidType.VERSION_CONTROL]},
    {naam: 'Svn', level: 4, type: [VaardigheidType.VERSION_CONTROL]}

  ];
